import React, {useState, useEffect} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import BrandGuidelinesNav from "../../components/brand-guidelines/brand-guidelines-nav";
import {StaticImage} from "gatsby-plugin-image";
import CustomLocalizedLink from "../../components/locales/custom-localized-link";

import corpLogo from './images/logo-corporate.png';
import specialUseLogo from './images/logo-special-use.png';
import clearLogo from './images/logo-clear.png';
import taglineLogo from './images/logo-tagline.png';
import taglineClearLogo from './images/logo-tagline-clear.png';
import productsLogo from './images/logo-product-logos.png';
import productsClearSpaceLogo from './images/logo-product-clear-space.png';
import logo1 from './images/logo-1.png';
import logo2 from './images/logo-2.png';
import logo3 from './images/logo-3.png';
import logo4 from './images/logo-4.png';
import logo5 from './images/logo-5.png';
import logo6 from './images/logo-6.png';
import logo7 from './images/logo-7.png';

const IndexPage = () => {

  const {t} = useTranslation();

  return (
      <Layout>
        <Seo
            title={t('brand_guidelines_title')}
            description={t('des_brand_guidelines')}
        />
        <div className="brand-guidelines">
          <section className={'brand-guidelines-header'}>
            <div className="container">
              <h1 className={'headline'}><Trans>bg_headline</Trans></h1>
              <div className="section-delimeter"></div>
              <BrandGuidelinesNav selected={'guidelines'}/>
              <h2><Trans>bg_nav_bg</Trans></h2>
            </div>
          </section>
          <section className={'logos'}>
            <div class="container">
              <h3 className={'highlight pad'}>
                <span>
                  <Trans>bg_logos</Trans>
                </span>
              </h3>
              <div className="grid-two-col">
                <div>
                  <img
                      src={corpLogo}
                      alt="Digilock Corporate Logo"
                      width={'343'}
                      height={'162'}
                      loading={'lazy'}
                  />
                </div>
                <div>
                  <h3><Trans>bg_corp_logo</Trans></h3>
                  <p><Trans>bg_corp_logo_copy</Trans></p>
                </div>
              </div>
              <div className="grid-two-col">
                <div>
                  <img
                      src={specialUseLogo}
                      alt="Digilock Special Use Logo"
                      width={'307'}
                      height={'118'}
                      loading={'lazy'}
                  />
                </div>
                <div>
                  <h3><Trans>bg_special_logo</Trans></h3>
                  <p dangerouslySetInnerHTML={{__html: t('bg_special_logo_copy')}}/>
                  <p dangerouslySetInnerHTML={{__html: t('bg_special_logo_copy2')}}/>
                </div>
              </div>
              <div className="grid-two-col">
                <div>
                  <img
                      src={clearLogo}
                      alt="Digilock Clear Logo"
                      width={'415'}
                      height={'172'}
                      loading={'lazy'}
                  />
                </div>
                <div>
                  <h3><Trans>bg_clear_logo</Trans></h3>
                  <p dangerouslySetInnerHTML={{__html: t('bg_clear_logo_copy')}}/>
                </div>
              </div>
              <div className="grid-two-col">
                <div>
                  <img
                      src={taglineLogo}
                      alt="Digilock Logo and Tagline"
                      width={'301'}
                      height={'220'}
                      loading={'lazy'}
                  />
                </div>
                <div>
                  <h3><Trans>bg_tagline_logo</Trans></h3>
                  <p dangerouslySetInnerHTML={{__html: t('bg_tagline_logo_copy')}}/>
                  <p dangerouslySetInnerHTML={{__html: t('bg_tagline_logo_copy2')}}/>
                  <p dangerouslySetInnerHTML={{__html: t('bg_tagline_logo_copy3')}}/>
                </div>
              </div>
              <div className="grid-two-col">
                <div>
                  <img
                      src={taglineClearLogo}
                      alt="Digilock Logo and Tagline Clear Spac"
                      width={'404'}
                      height={'248'}
                      loading={'lazy'}
                  />
                </div>
                <div>
                  <h3><Trans>bg_tagline_clear</Trans></h3>
                  <p dangerouslySetInnerHTML={{__html: t('bg_tagline_clear_copy')}}/>
                  <p dangerouslySetInnerHTML={{__html: t('bg_tagline_clear_copy2')}}/>
                </div>
              </div>
              <div className="grid-two-col">
                <div>
                  <img
                      src={productsLogo}
                      alt="Digilock Product Logos"
                      width={'447'}
                      height={'367'}
                      loading={'lazy'}
                  />
                </div>
                <div className={'center-middle'}>
                  <h3><Trans>bg_product_logos</Trans></h3>
                  <p dangerouslySetInnerHTML={{__html: t('bg_product_logos_copy')}}/>
                  <p dangerouslySetInnerHTML={{__html: t('bg_product_logos_copy2')}}/>
                </div>
              </div>
              <div className="grid-two-col last">
                <div>
                  <img
                      src={productsClearSpaceLogo}
                      alt="Digilock Product Logo Clear Space"
                      width={'316'}
                      height={'288'}
                      loading={'lazy'}
                  />
                </div>
                <div className={'center-middle'}>
                  <h3><Trans>bg_product_logo_clear_space</Trans></h3>
                  <p dangerouslySetInnerHTML={{__html: t('bg_product_logo_clear_space_copy')}}/>
                </div>
              </div>
            </div>
          </section>
          <section className={'logos-best-practices'}>
            <div class="container">
              <h3 className={'highlight pad'}>
                <span>
                <Trans>bg_logo_best_practices</Trans>
              </span>
              </h3>
              <div className="grid-two-col list">
                <div>
                  <p>1.</p>
                  <img
                      src={logo1}
                      alt="Not Allowed"
                      width={'423'}
                      height={'106'}
                      loading={'lazy'}
                  />
                </div>
                <div>
                  <p>2.</p>
                  <img
                      src={logo2}
                      alt="Not Allowed"
                      width={'423'}
                      height={'106'}
                      loading={'lazy'}
                  />
                </div>
              </div>
              <div className="grid-two-col list">
                <div>
                  <p>3.</p>
                  <img
                      src={logo3}
                      alt="Not Allowed"
                      width={'423'}
                      height={'106'}
                      loading={'lazy'}
                  />
                </div>
                <div>
                  <p>4.</p>
                  <img
                      src={logo4}
                      alt="Not Allowed"
                      width={'423'}
                      height={'106'}
                      loading={'lazy'}
                  />
                </div>
              </div>
              <div className="grid-two-col list">
                <div>
                  <p>5.</p>
                  <img
                      src={logo5}
                      alt="Not Allowed"
                      width={'423'}
                      height={'106'}
                      loading={'lazy'}
                  />
                </div>
                <div>
                  <p>6.</p>
                  <img
                      src={logo6}
                      alt="Not Allowed"
                      width={'423'}
                      height={'106'}
                      loading={'lazy'}
                  />
                </div>
              </div>
              <div className="grid-two-col list last">
                <div>
                  <p>7.</p>
                  <img
                      src={logo7}
                      alt="Not Allowed"
                      width={'423'}
                      height={'106'}
                      loading={'lazy'}
                  />
                </div>
              </div>
              <div class="best-practice-list-container">
                <div className={'best-practice-list'}>
                  <h3><Trans>bg_dont</Trans></h3>
                  <ol>
                    <li><Trans><strong><Trans>bg_donot</Trans></strong></Trans> <Trans>bg_dont_1</Trans></li>
                    <li><Trans><strong><Trans>bg_donot</Trans></strong></Trans> <Trans>bg_dont_2</Trans></li>
                    <li><Trans><strong><Trans>bg_donot</Trans></strong></Trans> <Trans>bg_dont_3</Trans></li>
                    <li><Trans><strong><Trans>bg_donot</Trans></strong></Trans> <Trans>bg_dont_5</Trans></li>
                    <li><Trans><strong><Trans>bg_donot</Trans></strong></Trans> <Trans>bg_dont_4</Trans></li>
                    <li><Trans><strong><Trans>bg_donot</Trans></strong></Trans> <Trans>bg_dont_6</Trans></li>
                    <li><Trans><strong><Trans>bg_donot</Trans></strong></Trans> <Trans>bg_dont_7</Trans></li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className={'colors'}>
            <div class="container">
              <h3 className={'highlight'}>
                <span>
                <Trans>bg_colors</Trans>
              </span>
              </h3>
              <p>
                <Trans>bg_colors_copy</Trans>
              </p>
              <div class="color-circles">
                <div class="circle steadfast">
                  <ul>
                    <li>STEADFAST</li>
                    <li>HEX : #002A3A</li>
                    <li>RGB : 0  42  58</li>
                    <li>CMYK : 97  73  52  56</li>
                    <li>PMS : 303 C 6</li>
                  </ul>
                </div>
                <div className="circle trust">
                  <ul>
                    <li>TRUST</li>
                    <li>HEX : #009ABF</li>
                    <li>RGB : 0  154  191</li>
                    <li>CMYK : 82  21  17  0</li>
                    <li>PMS : 7703 C</li>
                  </ul>
                </div>
                <div className="circle create">
                  <ul>
                    <li>CREATE</li>
                    <li>HEX : #FF6020</li>
                    <li>RGB : 255  96  32</li>
                    <li>CMYK : 0  77  96  0</li>
                    <li>PMS : BRIGHT</li>
                    <li>ORANGE C</li>
                  </ul>
                </div>
                <div className="circle white">
                  <ul>
                    <li>WHITE</li>
                    <li>HEX : #FFFFFF</li>
                    <li>RGB : 255  255  255</li>
                    <li>CMYK : 0  0  0  0</li>
                    <li>WHITE</li>
                  </ul>
                </div>
                <div className="circle innovation">
                  <ul>
                    <li>INNOVATION</li>
                    <li>HEX : #96989A</li>
                    <li>RGB : 152  152  154</li>
                    <li>CMYK : 43  35  35  1</li>
                    <li>PMS : COOL GRAY 7</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className={'typography'}>
            <div className="container">
              <h3 className={'highlight'}>
                <span>
                <Trans>bg_typography</Trans>
              </span>
              </h3>
              <p>
                <Trans>bg_typography_copy</Trans>
              </p>
              <div className="grid-two-col typo last">
                <div className={'regular'}>
                  <div>
                    AaBb<br/>
                    CcDc
                  </div>
                  <div>
                    <span>AVENIR NEXT LT PTO REGULAR</span><br/>
                    AaBbCcDdEeFfGgHhIiJjKk <br/>
                    LlMmNnOoPpQqRrSsTtUu <br/>
                    VvWwXxYyZz <br/>
                    1234567890!@#$%^&*()
                  </div>
                </div>
                <div className={'demi'}>
                  <div>AaBb<br/>CcDd</div>
                  <div>
                    <span>AVENIR NEXT LT PTO DEMI</span><br/>
                    AaBbCcDdEeFfGgHhIiJjKk <br/>
                    LlMmNnOoPpQqRrSsTtUu <br/>
                    VvWwXxYyZz <br/>
                    1234567890!@#$%^&*()
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="bottom-nav">
            <div class="container">
              <div className="section-delimeter"></div>
              <CustomLocalizedLink label={t('bg_nav_resources')+' &gt;'} goto={'/brand-guidelines/brand-resources/'} cls={'bg-footer-nav'} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
